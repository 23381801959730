import { Link } from "react-router-dom";
import useFetchCollection from "../../utils/useFetchCollection";
import Layout from "../../utils/layout";
import { AdminAuthContext } from "../../utils/adminAuthContext";
import { useContext, useEffect } from "react";

function AdminPage() {
  const { data, isLoading } = useFetchCollection("courses");
  const authContext = useContext(AdminAuthContext);
  useEffect(() => {
    if (authContext.admin == null) {
      const password = prompt("Please Enter your Name");
      if (password == "10811801sS") {
        authContext.setAdmin("admin");
      } else {
        alert("Noto'gri parol");
      }
    }
  }, []);
  if (authContext.admin == null) {
    return <h1>404</h1>;
  }
  return (
    <Layout>
      {isLoading && <div>Yuklanmoqda....</div>}

      <div className=" space-y-4 text-xl">
        {data &&
          data.map((course) => {
            return (
              <Link
                to={`/youcandoit/ebd7b28d-766e-4575-9c56-e7bbf7ca357c/${course.id}`}
                className=" text-blue-200 block"
              >
                {course.title}
              </Link>
            );
          })}
      </div>
    </Layout>
  );
}

export default AdminPage;
