import { createContext, useState } from "react";

export const CoursesContext = createContext();

export const CoursesProvider = ({ children }) => {
  const [coursesList, setCoursesList] = useState([]);

  const value = {
    coursesList,
    setCoursesList,
  };

  return (
    <CoursesContext.Provider value={value}>{children}</CoursesContext.Provider>
  );
};
