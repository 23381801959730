import { Triangle } from "react-loader-spinner";

function LoadingKit() {
  return (
    <div className="flex flex-col bg-black items-center h-screen justify-center">
      <Triangle
        visible={true}
        height="80"
        width="80"
        ariaLabel="yuklanmoqda"
        wrapperStyle={{}}
        wrapperClass="dna-wrapper"
        color="#ff0b53"
      />
    </div>
  );
}

export default LoadingKit;
