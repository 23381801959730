import ChromeDinoComponent from "../../dino/components/Dino";

function AyubxonettoPage() {
  return (
    <div className="  md:scale-125 lg:scale-150 ">
      <ChromeDinoComponent />
    </div>
  );
}

export default AyubxonettoPage;
