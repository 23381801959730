function HeroBtn(props) {
  const onPress = () => {
    props.refScroll.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div class="container">
      <div className="btn">
        <button onClick={onPress}>
          <span className=" mr-2"> {props.icon} </span>
          {props.text}
        </button>
      </div>
    </div>
  );
}

export default HeroBtn;
