function Coursemap({ coursesList }) {
  return (
    <div className="p-6 md:p-0 mt-16">
      <h2 className="text-xl md:text-2xl font-semibold">Kursimiz Mavzulari:</h2>
      <ol className="mt-8">
        {coursesList.map((course, index) => {
          return (
            <li
              class={`${
                index + 1 == coursesList.length
                  ? "border-l-2 md:border-l-0"
                  : "border-l-2"
              }  border-purple-600`}
            >
              <div class="md:flex flex-start">
                <div class="bg-purple-600 w-10 h-10 flex text-xl items-center justify-center rounded-full -ml-5">
                  {index + 1}
                </div>
                <div class="block p-6 rounded-lg shadow-lg bg-slate-800 w-full  ml-6 mb-10">
                  {course.name}
                </div>
              </div>
            </li>
          );
        })}
      </ol>
    </div>
  );
}

export default Coursemap;
