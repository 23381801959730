import { Link, useParams } from "react-router-dom";
import Overview from "./overview";
import Coursemap from "./coursemap";
import useFetchDocument from "../../utils/useFetchDocument";
import Layout from "../../utils/layout";
import LoadingKit from "../../utils/loading";

function DarsimizDetailPage() {
  const { courseId } = useParams();
  const { data, isLoading } = useFetchDocument("courses", courseId);
  return (
    <>
      {isLoading && <LoadingKit />}

      {data && (
        <Layout>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div className=" col-span-1 order-2  md:order-1">
              <h1 className="text-2xl md:text-4xl font-semibold text-white">
                {data.title}
              </h1>
              <p className="text-gray-300  md:text-xl mt-4">
                {data.description}
              </p>
              <div className="space-y-2 mt-10">
                <p className=" md:text-xl">Sotib olmoqchimisiz?</p>
                <div className="space-y-4">
                  <div className="space-x-4">
                    <span>Telefon qiling:</span>
                    <a
                      className="bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90% inline-block px-4 py-1 rounded-full text-lg hover:scale-105"
                      href="tel:+998330015040"
                    >
                      +998 33 001 50 40
                    </a>
                  </div>
                  <div className="space-x-4">
                    <span>Telegramda yozing:</span>
                    <a
                      href="https://t.me/obunetto"
                      target="__blank"
                      className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 inline-block px-4 py-1 rounded-full text-lg hover:scale-105"
                    >
                      @obunetto
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-span-1 order-1 md:order-2">
              <iframe
                title="vimeo-player"
                src="https://player.vimeo.com/video/880071044?h=5f0e00ae41"
                // frameborder="0"
                className="rounded-xl aspect-video w-full"
                allow=" fullscreen; picture-in-picture"
                allowfullscreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen"
              ></iframe>
              {/* <video controls className="rounded-xl">
                <source src="/savodxonlik.mp4" type="video/mp4" />
              </video> */}
            </div>
          </div>
          <Overview />
          <Coursemap coursesList={data.lectures} />
        </Layout>
      )}
    </>
  );
}

export default DarsimizDetailPage;
