import { useContext, useEffect, useState } from "react";
import { CoursesContext } from "./coursesContext";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "./firebase";

function useFetchCollection(collectionToFetch) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const querySnap = await getDocs(collection(db, collectionToFetch));
      return querySnap;
    };
    let list = [];
    fetchData().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        list.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      setData(list);
      setIsLoading(false);
    });
  }, []);
  return { data, isLoading };
}

export default useFetchCollection;
