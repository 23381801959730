import { Link } from "react-router-dom";

function CourseListItem(props) {
  return (
    <Link
      to={props.to}
      className={`${
        props.isActive ? " bg-slate-700 text-blue-200" : ""
      } space-x-4  block hover:bg-slate-700 rounded p-2 hover:cursor-pointer text-gray-100 hover:text-blue-200`}
    >
      <span className=" text-gray-500">{props.position}</span>
      <span className=" font-semibold text-lg ">{props.title}</span>
    </Link>
  );
}

export default CourseListItem;
