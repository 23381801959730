import { useContext, useEffect, useState } from "react";
import { CoursesContext } from "./coursesContext";
import { doc, getDoc } from "firebase/firestore";

import { db } from "./firebase";

function useFetchDocument(parentCollection, documentId) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const docRef = doc(db, parentCollection, documentId);
      const docSnap = await getDoc(docRef);

      return docSnap;
    };

    fetchData().then((docSnap) => {
      if (docSnap.exists()) {
        setData(docSnap.data());
        setIsLoading(false);
      } else {
        console.log("No such document!");
      }
    });
  }, []);
  return { data, isLoading };
}

export default useFetchDocument;
