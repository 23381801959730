import { createContext, useState } from "react";

export const AdminAuthContext = createContext();

export const AdminAuthContextProvider = ({ children }) => {
  const [admin, setAdmin] = useState(null);

  const value = {
    admin,
    setAdmin,
  };

  return (
    <AdminAuthContext.Provider value={value}>
      {children}
    </AdminAuthContext.Provider>
  );
};
