import { Link } from "react-router-dom";

function GameCard(props) {
  return (
    <Link to={props.to}>
      <div className="relative max-w-xs mx-auto group">
        <div className="w-full h-[300px]   bg-myBrightRed absolute  -top-8 -left-4 group-hover:bg-myCherryRed">
          <span className="absolute bottom-4 left-2 leading-none rotate-180 [writing-mode:vertical-lr]  text-white  font-bold inline-blocks uppercase tracking-widest">
            {props.title}
          </span>
        </div>
        <div className="relative left-4">
          <img
            className="w-full h-[300px] object-center object-cover relative rounded-tr-[40px] rounded-bl-[40px]"
            src={props.src}
            alt=""
          />
        </div>
      </div>
    </Link>
  );
}

export default GameCard;
