import { useParams } from "react-router-dom";
import Layout from "../../utils/layout";
import useFetchDocument from "../../utils/useFetchDocument";
import { ReactSortable } from "react-sortablejs";
import { useContext, useEffect, useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../utils/firebase";
import { AdminAuthContext } from "../../utils/adminAuthContext";

function AdminCourseDetailPage() {
  const { courseId } = useParams();
  const [lecturesList, setLecturesList] = useState([]);
  const { data, isLoading } = useFetchDocument("courses", courseId);
  useEffect(() => {
    data && setLecturesList(data.lectures);
  }, [data]);
  const authContext = useContext(AdminAuthContext);
  useEffect(() => {
    if (authContext.admin == null) {
      const password = prompt("Please Enter your Name");
      if (password == "10811801sS") {
        authContext.setAdmin("admin");
      } else {
        alert("Noto'gri parol");
      }
    }
  }, []);
  if (authContext.admin == null) {
    return <h1>404</h1>;
  }

  const onUpdateLectures = async () => {
    const courseRef = doc(db, "courses", courseId);
    await updateDoc(courseRef, {
      lectures: lecturesList,
    });
    alert("Muvaffaqqiyatli o'zgardi");
  };

  return (
    <Layout>
      {isLoading && <div>Yuklanmoqda....</div>}
      {data && (
        <div className=" max-w-xl mx-auto">
          <h1 className=" text-2xl font-semibold border-b mb-8">
            {data.title}
          </h1>
          <ReactSortable
            className="space-y-4"
            list={lecturesList}
            setList={setLecturesList}
          >
            {lecturesList.map((item, index) => (
              <div
                className="py-1 px-2 bg-slate-800 rounded cursor-move  text-lg"
                key={item.id}
              >
                {index + 1}. {item.name}
              </div>
            ))}
          </ReactSortable>
          {data.lectures != lecturesList && (
            <button
              onClick={onUpdateLectures}
              className=" mt-8 bg-green-400 text-gray-900 font-bold px-4 py-2 rounded hover:scale-105"
            >
              O'zgartirish
            </button>
          )}
        </div>
      )}
    </Layout>
  );
}

export default AdminCourseDetailPage;
