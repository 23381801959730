import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/Home";
import AyubxonettoPage from "./pages/Ayubxonetto";
import { useEffect } from "react";
import DarsimizPage from "./pages/Darsimiz";
import NotFound from "./pages/404";
import { CoursesProvider } from "./utils/coursesContext";
import YordamchiPage from "./pages/Yordamchi";
import { AuthContextProvider } from "./utils/authContext";
import DarsimizDetailPage from "./pages/DarsimizDetail";
import AdminPage from "./pages/Admin";
import AdminCourseDetailPage from "./pages/AdminCourseDetail";
import { AdminAuthContextProvider } from "./utils/adminAuthContext";

function App() {
  return (
    <AdminAuthContextProvider>
      <AuthContextProvider>
        <CoursesProvider>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/ayubxonetto" element={<AyubxonettoPage />} />
            <Route path="/yordamchi" element={<YordamchiPage />} />
            <Route path="/darsimiz/:courseId">
              <Route path=":lectureId" element={<DarsimizPage />} />
            </Route>
            <Route
              path="/batafsil/:courseId"
              element={<DarsimizDetailPage />}
            />
            <Route
              path="/youcandoit/ebd7b28d-766e-4575-9c56-e7bbf7ca357c"
              element={<AdminPage />}
            />
            <Route
              path="/youcandoit/ebd7b28d-766e-4575-9c56-e7bbf7ca357c/:courseId"
              element={<AdminCourseDetailPage />}
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </CoursesProvider>
      </AuthContextProvider>
    </AdminAuthContextProvider>
  );
}

export default App;
