function Overview() {
  return (
    <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-8 mt-24 border-b border-slate-800 pb-4 text-indigo-200">
      {overwievitems.map((e) => {
        return (
          <div className="flex space-x-2">
            {e.icon}
            <span>{e.title}</span>
          </div>
        );
      })}
    </div>
  );
}

export default Overview;

const videoIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#c7d2fe"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M9 16.985v-10.021l9 5.157-9 4.864zm4-14.98c5.046.504 9 4.782 9 9.97 0 1.467-.324 2.856-.892 4.113l1.738 1.006c.732-1.555 1.154-3.285 1.154-5.119 0-6.303-4.842-11.464-11-11.975v2.005zm-10.109 14.082c-.568-1.257-.891-2.646-.891-4.112 0-5.188 3.954-9.466 9-9.97v-2.005c-6.158.511-11 5.672-11 11.975 0 1.833.421 3.563 1.153 5.118l1.738-1.006zm17.213 1.734c-1.817 2.523-4.769 4.175-8.104 4.175s-6.288-1.651-8.105-4.176l-1.746 1.011c2.167 3.122 5.768 5.169 9.851 5.169 4.082 0 7.683-2.047 9.851-5.168l-1.747-1.011z" />
  </svg>
);

const infiniteIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    fill="#c7d2fe"
    viewBox="0 0 28 28"
  >
    <path d="M8.146 9.089l1.138-1.557c.484.394.949.834 1.407 1.302l-1.244 1.561c-.422-.483-.848-.93-1.301-1.306zm10.426-1.089c2.113 0 3.479 1.578 3.479 4 0 .294-.021.575-.061.843l1.914.383c.062-.392.096-.801.096-1.226 0-3.545-2.231-6-5.428-6-6.017 0-8.414 10-13.142 10-2.114 0-3.48-1.578-3.48-4 0-.294.021-.575.061-.843l-1.914-.383c-.063.392-.097.801-.097 1.226 0 3.545 2.232 6 5.43 6 6.003 0 8.406-10 13.142-10zm-18.245 1.801l1.94.388c.159-.422.37-.8.643-1.11l-1.401-1.4c-.525.582-.924 1.301-1.182 2.122zm4.673-3.78c-1.049.075-1.981.419-2.748.986l1.447 1.447c.382-.22.818-.36 1.301-.413v-2.02zm3.476.922c-.742-.475-1.551-.802-2.476-.901v2.004c.477.074.912.244 1.322.475l1.154-1.578zm7.378 7.967c-.451-.375-.877-.82-1.297-1.302l-1.244 1.561c.456.466.921.905 1.404 1.298l1.137-1.557zm5.879-1.099c-.16.422-.371.8-.643 1.11l1.4 1.401c.525-.582.925-1.301 1.182-2.123l-1.939-.388zm-3.733 4.147v-2.004c-.477-.074-.911-.245-1.322-.477l-1.153 1.579c.741.476 1.551.803 2.475.902zm2.302-2.412c-.383.22-.818.36-1.302.413v2.021c1.049-.075 1.981-.419 2.748-.986l-1.446-1.448z" />
  </svg>
);

const languageIcon = (
  <svg
    width="24"
    height="24"
    fill="#c7d2fe"
    xmlns="http://www.w3.org/2000/svg"
    fill-rule="evenodd"
    clip-rule="evenodd"
  >
    <path d="M12.02 0c6.614.011 11.98 5.383 11.98 12 0 6.623-5.376 12-12 12-6.623 0-12-5.377-12-12 0-6.617 5.367-11.989 11.981-12h.039zm3.694 16h-7.427c.639 4.266 2.242 7 3.713 7 1.472 0 3.075-2.734 3.714-7m6.535 0h-5.523c-.426 2.985-1.321 5.402-2.485 6.771 3.669-.76 6.671-3.35 8.008-6.771m-14.974 0h-5.524c1.338 3.421 4.34 6.011 8.009 6.771-1.164-1.369-2.059-3.786-2.485-6.771m-.123-7h-5.736c-.331 1.166-.741 3.389 0 6h5.736c-.188-1.814-.215-3.925 0-6m8.691 0h-7.685c-.195 1.8-.225 3.927 0 6h7.685c.196-1.811.224-3.93 0-6m6.742 0h-5.736c.062.592.308 3.019 0 6h5.736c.741-2.612.331-4.835 0-6m-12.825-7.771c-3.669.76-6.671 3.35-8.009 6.771h5.524c.426-2.985 1.321-5.403 2.485-6.771m5.954 6.771c-.639-4.266-2.242-7-3.714-7-1.471 0-3.074 2.734-3.713 7h7.427zm-1.473-6.771c1.164 1.368 2.059 3.786 2.485 6.771h5.523c-1.337-3.421-4.339-6.011-8.008-6.771" />
  </svg>
);

const teacherIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    fill="#c7d2fe"
    viewBox="0 0 24 22"
  >
    <path d="M12 2c2.757 0 5 2.243 5 5.001 0 2.756-2.243 5-5 5s-5-2.244-5-5c0-2.758 2.243-5.001 5-5.001zm0-2c-3.866 0-7 3.134-7 7.001 0 3.865 3.134 7 7 7s7-3.135 7-7c0-3.867-3.134-7.001-7-7.001zm6.369 13.353c-.497.498-1.057.931-1.658 1.302 2.872 1.874 4.378 5.083 4.972 7.346h-19.387c.572-2.29 2.058-5.503 4.973-7.358-.603-.374-1.162-.811-1.658-1.312-4.258 3.072-5.611 8.506-5.611 10.669h24c0-2.142-1.44-7.557-5.631-10.647z" />
  </svg>
);

const overwievitems = [
  {
    icon: videoIcon,
    title: "20+ HD videolar",
  },
  {
    icon: infiniteIcon,
    title: "Bir umr foydalaning",
  },
  {
    icon: teacherIcon,
    title: "Ustoz: Sardoretto",
  },
];
