import { Link } from "react-router-dom";

function CourseCard(props) {
  return (
    <Link to={props.to}>
      <div className=" bg-gray-900 shadow-xl rounded-xl overflow-hidden hover:scale-105 p-2 border-t  transition ease-in-out  border-b border-myBrightRed">
        <img
          className=" aspect-video w-full rounded-lg object-contain"
          src={props.src}
        />
        <h2 className="p-2 font-semibold text-center">{props.title}</h2>
      </div>
    </Link>
  );
}

export default CourseCard;
