import { motion } from "framer-motion";
import HeroBtn from "./heroBtn";
import "./Home.css";
import HeroLink from "./heroLink";
import CourseCard from "./courseCard";
import GameCard from "./gameCard";
import { useRef } from "react";
import { courseIcon, gameIcon, youtubeIcon } from "../../utils/icons";

const coursesArray = [
  {
    id: "EZkQIbB0e6XB28mbsUC5",
    title: "HTML kursi Veb-saytlar qanday ishlaydi?",
    iamgeURL:
      "https://drive.google.com/uc?id=1Istz87Pg077YkW6prQ6lve6bZ0wb-y3J",
  },
];

function HomePage() {
  const coursesRef = useRef(null);
  const gamesRef = useRef(null);

  return (
    <div className="bg-[#121116] font-mono">
      <div className=" w-full min-h-screen hero">
        <div className="flex flex-col items-center justify-center">
          <motion.img
            initial={{ opacity: 0.5, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 2 }}
            src="/heroInfo.png"
            className=" max-w-xs sm:max-w-sm md:max-w-2xl"
          />
          <div className="flex flex-col md:flex-row space-y-10 md:space-y-0 md:space-x-8">
            <HeroBtn
              icon={gameIcon}
              text={"O'yinlarimiz"}
              refScroll={gamesRef}
            />
            <div className=" scale-110">
              <HeroBtn
                icon={courseIcon}
                text={"Darslarimiz"}
                refScroll={coursesRef}
              />
            </div>
            <HeroLink
              icon={youtubeIcon}
              text={"YouTube"}
              href={"https://www.youtube.com/@sardoretto"}
            />
          </div>
        </div>
      </div>
      <div className=" bg-slate-900 text-gray-100" ref={coursesRef}>
        <CenterSection />
      </div>
      <div className=" bg-[#121116] text-gray-100">
        <section className=" max-w-5xl mx-auto p-10  ">
          <SectionHeader title={"So'nggi darslarimiz"} />
          <div className=" grid grid-cols-1 md:grid-cols-2 py-10 gap-10">
            <CourseCard
              src={
                "https://drive.google.com/uc?id=1HUlSfjmre5EB7irbMD6uizpnw9nF4gP_"
              }
              title={"Online Kompyuter savodxonligi"}
              to={"batafsil/6nQT9Rf6mSRHO7cNl7nI"}
            />
            <CourseCard
              src={
                "https://drive.google.com/uc?id=1ZXcOJW6ya024p_oVd4gXlNdQZ09KPRG6"
              }
              title={"Offline Kompyuter savodxonligi"}
              to={
                "darsimiz/wgxGAlvXw0o2d8Py2U7D/661ba699-e475-44b9-8ef3-cf3a2eff7755"
              }
            />
            <CourseCard
              src={
                "https://drive.google.com/uc?id=1661p0ldDEG0g_3YQsZjkgBGAbo8UgtWV"
              }
              title={"HTML kursi Veb-saytlar qanday ishlaydi?"}
              to={
                "darsimiz/EZkQIbB0e6XB28mbsUC5/37ca7051-ea8e-4ec3-8d56-0298e6bd136f"
              }
            />
            <CourseCard
              src={
                "https://drive.google.com/uc?id=1Istz87Pg077YkW6prQ6lve6bZ0wb-y3J"
              }
              title={"1 soat ichida git asoslari"}
              to={
                "darsimiz/wGT379SifLm2H5RpYMRC/a910b320-95ff-4a32-93c0-6e9da35ec8b9"
              }
            />
            <CourseCard
              src={
                "https://drive.google.com/uc?id=1LtMAihYJfD1uHbTNQYjKU7sFkVilisKL"
              }
              title={"Zamonaviy React JS bilan sayt boshlanishi"}
              to={
                "darsimiz/js6SSmCwpnAFUrRWYwaD/726367cf-9b6d-4fa8-a0db-5e38635db84e"
              }
            />
          </div>
        </section>
      </div>
      <div className=" p-10">
        <div className=" bg-black rounded-xl text-white  ">
          <section
            className=" rounded-xl  max-w-5xl mx-auto p-10"
            ref={gamesRef}
          >
            <SectionHeader title={"Sardoretto o'yinlari"} />
            <div className=" grid grid-cols-1  sm:grid-cols-2 md:grid-cols-3 py-20  px-4  gap-16">
              <GameCard
                title={"Ayubozavr"}
                to={"/ayubxonetto"}
                src={
                  "https://drive.google.com/uc?id=1czINgnOdRBA87fvz34GxGf7ZEpAdb-S9"
                }
              />
              {/* <GameCard
                title={"Virtual yordamchi"}
                to={"/yordamchi"}
                src={"/sardoretto.jpg"}
              /> */}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default HomePage;

function SectionHeader(props) {
  return (
    <h1 className=" text-2xl border-b border-myCherryRed pb-2 border-opacity-80">
      {props.title}
    </h1>
  );
}

function CenterSection(params) {
  return (
    <div className="max-w-5xl mx-auto p-10 pb-16 ">
      <SectionHeader title={"O'quv Markazimiz haqida ma'lumot"} />
      <h2 className=" mt-12 text-xl text-gray-300">
        Sizga qaysi darslarimiz ko'proq ma'qul?
      </h2>
      <div class="  grid grid-cols-1 md:grid-cols-2  text-gray-800 gap-16 mt-10 ">
        <CenterCard
          color={"#FFFBEC"}
          title={"Kompyuter Savodxonligi"}
          subtitle={"Ustoz: Sardoretto"}
          body={"0 dan boshlab Kompyuterni"}
          price={"990 000 so'm"}
          // src={"/children.png"}
          src={"/lover.png"}
          ageText={"Barcha yoshdagilarga"}
          array={kidsSkills}
          discountText={"Ingliz Tili kursimizga 10% chegirma"}
        />
        <CenterCard
          color={"#F9ECFF"}
          title={"Ingliz Tili"}
          subtitle={"Ustoz: Sardoretto"}
          price={"790 000 so'm"}
          src={"/lover.png"}
          ageText={"Barcha yoshdagilarga"}
          array={grownSkills}
          discountText={"Kompyuter Savodxonligi kursimizga 10% chegirma"}
        />
      </div>
    </div>
  );
}

function CenterCard(props) {
  return (
    <div className={`bg-[${props.color}] rounded-xl`}>
      <div className="flex flex-col p-8 rounded-xl bg-white shadow-xl translate-x-4 translate-y-4   md:w-auto">
        <div className=" flex items-center space-x-4">
          <img src={props.src} class="w-12" alt="yoshni anglatuvchi rasm" />
          <span className="text-lg text-myCherryRed font-semibold">
            {props.ageText}
          </span>
        </div>
        <div className=" font-semibold mt-3 lg:text-lg">{props.title}</div>
        <div className="text-md font-light w-60 md:w-auto">
          {props.subtitle}
        </div>
        <ul className=" list-outside list-disc text-sm mt-6    lg:h-[260px]">
          {props.array.map((skill, index) => {
            return <li key={index}>{skill}</li>;
          })}
        </ul>
        <span className="text-sm h-10 text-green-600">
          {props.discountText}
        </span>
        <div className="my-4">
          <span className="font-bold text-base">{props.price} </span>
          <span className="font-light text-sm"> / oylik</span>
        </div>
        <a
          href="https://t.me/obunetto"
          target="__blank"
          className={`bg-[#F4F5FA] text-center text-sm px-4 py-3 rounded-full  shadow-xl mt-4 hover:bg-[${props.color}] hover:bg-blue-100`}
        >
          Ba'tafsil ma'lumot uchun telegram: @obunetto
        </a>
      </div>
    </div>
  );
}

const kidsSkills = [
  "Windows kompyuterlari asoslari",
  "Klaviaturada tezroq yozish",
  "Veb brauzerlardan maksimal foydalanish",
  "Microsoft Word, Google Docs matn protsessorilari",
  "Google Slides bilan prezentatsiyalar yaratish",
  "Microsoft Excel va Google Sheets",
  "Dasturlashning barcha yo'nalishlarini tushuntirish",
];

const grownSkills = [
  "So'zlarni to'gri talaffus etish",
  "To'g'ri grammatikadan foydalanish",
  "Ingliz tilida muloqot qilish ",
  "Murakkab gaplar kompozitsiya qilish",
  "Ingliz tilida fikr yuritih",
  "Speaking, Listening, Reading va Writing",
  "Ingliz tilida taqdimot qilish",
];
