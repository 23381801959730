import { Link } from "react-router-dom";

function Layout({ children }) {
  return (
    <div className="bg-slate-900 min-h-screen h-full  text-gray-100 p-4 pb-44">
      <header className=" max-w-7xl mx-auto border-b border-myCherryRed border-opacity-50 pb-2">
        <Link
          className=" text-myCherryRed text-xl font-semibold font-mono"
          to={"/"}
        >
          Sardoretto
        </Link>
      </header>
      <div className="max-w-5xl mx-auto p-4 mt-12">{children}</div>
    </div>
  );
}

export default Layout;
