import { useEffect, useState } from "react";
import { ResultReason } from "microsoft-cognitiveservices-speech-sdk";
import { getTokenOrRefresh } from "./token_util";
import { useNavigate } from "react-router-dom";

const speechsdk = require("microsoft-cognitiveservices-speech-sdk");

function YordamchiPage() {
  const [displayText, setDisplayText] = useState("Savol bering...");
  const [isBtnActive, setIsBtnActive] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const func = async () => {
      const tokenRes = await getTokenOrRefresh();

      if (tokenRes.authToken === null) {
        setDisplayText(tokenRes.error);
      }
    };
    func();
  }, []);

  const sttFromMic = async () => {
    setIsBtnActive(true);
    const tokenObj = await getTokenOrRefresh();

    const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(
      tokenObj.authToken,
      tokenObj.region
    );
    speechConfig.speechRecognitionLanguage = "uz-UZ";
    const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();

    const recognizer = new speechsdk.SpeechRecognizer(
      speechConfig,
      audioConfig
    );

    setDisplayText("gapiring...");

    recognizer.recognizeOnceAsync((result) => {
      let displayingText;
      if (result.reason === ResultReason.RecognizedSpeech) {
        // displayingText = `Gapingiz: ${result.text}`;
        displayingText = `Agar javop ola olmagan bo'lsangiz, tugma bosgandan so'ng biroz kutib keyin savol bering!`;
      } else {
        displayingText = "Xato: Nimadur xato ketdi! Mikofoningiz yoqiqmi? ";
      }
      setIsBtnActive(false);
      setDisplayText(displayingText);
      // setDisplayText(
      //   "Uncaught DOMException: Failed to execute 'insertRule' on 'CSSStyleSheet': Failed to insert the rule."
      // );
      textIntoAction(result.text);
    });
  };

  const textIntoAction = (response) => {
    let audio;
    let string = response.toLowerCase();
    if (
      string.includes("bugun obhavo qanday") ||
      string.includes("bugun ob havo qanday")
    ) {
      audio = new Audio("/havo.mp3");
    } else if (
      string.includes("salom sardor etdi") ||
      string.includes("salom sardor it to")
    ) {
      audio = new Audio("/salom.mp3");
    } else if (string.includes("qarsak chala olasanmi")) {
      audio = new Audio("/applause.mp3");
    } else if (string.includes("o'yinga qo'y")) {
      navigate("/ayubxonetto");
      return;
    } else {
      audio = new Audio("/tushun.mp3");
    }
    audio.play();
  };

  return (
    <div className=" bg-black  min-h-screen text-white">
      {/* <h1 className=" text-3xl text-center">{displayText}</h1> */}
      <p className="pt-2 text-center">
        JUDA HAM KO'P SO'ROV TUFAYLI HOZIRCHA SERVISNI ISHLATA OLMAYSIZ, ERTAGA
        HARAKAT QILING
      </p>
      <p className="pt-4 text-center">
        Gapirishdan oldin mikrofon yoqiq ekanligiga amin bo'ling, rahmat!
      </p>
      <p className="pt-4 text-center">
        Quyidagi Savollarni so'rasangiz bo'ladi:{" "}
        <span className=" text-myCherryRed">
          bugun obhavo qanday, salom sardoretto, qarsak chala olasanmi, o'yinga
          qo'y
        </span>
      </p>
      <div className="flex  justify-center items-center relative mt-48 md:mt-64">
        <button
          className=" absolute     duration-300 ease-in-out hover:scale-95 z-50 rounded-full overflow-hidden ring-2  ring-myBrightRed"
          // onClick={() => sttFromMic()}
        >
          <img src="/sardoretto.png" className="w-64" />
        </button>
        {isBtnActive && (
          <>
            <div className=" absolute animate-ping     w-56 h-56   bg-myBrightRed rounded-full"></div>
            <div className=" absolute animate-ping     w-48 h-48  bg-red-500 rounded-full"></div>
            <div className=" absolute animate-ping     w-40 h-40   bg-myCherryRed rounded-full"></div>
          </>
        )}
      </div>
    </div>
  );
}

export default YordamchiPage;
