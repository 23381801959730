function HeroLink(props) {
  return (
    <div class="container">
      <div className="btn">
        <a href={props.href} target="__blank" className=" ">
          <span className=" mr-2"> {props.icon}</span>
          {props.text}
        </a>
      </div>
    </div>
  );
}

export default HeroLink;
