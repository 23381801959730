import React, { useEffect, useRef, useState } from "react";

import Resources from "./Resources.js";
import DinoScript from "./DinoScript.js";
import DinoStyle from "./DinoStyle.js";

import "./Dino.css";

function ChromeDinoComponent() {
  const refContainer = useRef(null);
  const startContainer = useRef(null);
  const [isGameLanded, setIsGameLanded] = useState(false);

  const appendDinoScript = () => {
    let dinoScriptContainer = document.createElement("script");
    dinoScriptContainer.appendChild(document.createTextNode(DinoScript));
    // this.startDiv.appendChild(dinoScriptContainer);
    startContainer.current.appendChild(dinoScriptContainer);
  };

  const appendRunnerScript = () => {
    let runnerScriptContainer = document.createElement("script");
    runnerScriptContainer.appendChild(
      document.createTextNode(`new Runner('.interstitial-wrapper');`)
    );
    refContainer.current.appendChild(runnerScriptContainer);

    // this.endDiv.appendChild(runnerScriptContainer);
  };

  useEffect(() => {
    appendDinoScript();
    appendRunnerScript();
  }, []);

  return (
    <div ref={startContainer}>
      <style>{DinoStyle}</style>
      <div id="main-frame-error" className="interstitial-wrapper">
        <Resources />
        <div ref={refContainer}></div>
      </div>
    </div>
  );
}

export default ChromeDinoComponent;

// class ChromeDinoComponent extends React.Component {
//   appendDinoScript() {
//     let dinoScriptContainer = document.createElement("script");
//     dinoScriptContainer.appendChild(document.createTextNode(DinoScript));
//     this.startDiv.appendChild(dinoScriptContainer);
//   }

//   appendRunnerScript() {
//     let runnerScriptContainer = document.createElement("script");
//     runnerScriptContainer.appendChild(
//       document.createTextNode(`new Runner('.interstitial-wrapper');`)
//     );

//     this.endDiv.appendChild(runnerScriptContainer);
//   }

//   componentDidMount() {
//     this.appendDinoScript();

//     this.appendRunnerScript();
//   }

//   render() {
//     return (
//       <div ref={(el) => (this.startDiv = el)}>
//         <style>{DinoStyle}</style>
//         <div id="main-frame-error" className="interstitial-wrapper">
//           <Resources />
//           <div ref={(el) => (this.endDiv = el)}></div>
//         </div>
//       </div>
//     );
//   }
// }

// export default ChromeDinoComponent;
