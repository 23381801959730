import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../utils/authContext";

function AuthBox() {
  const [usernameInp, setUsernameInp] = useState("");
  const [passwordInp, setPasswordInp] = useState("");
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const para = document.createElement("div");
    para.className =
      "flex flex-wrap gap-0.5 h-screen items-center justify-center  relative";
    let el =
      '<div class = "  transition-colors duration-[1.5s] hover:duration-[0s] border-myCherryRed h-[calc(5vw-2px)] w-[calc(5vw-2px)] md:h-[calc(4vw-2px)] md:w-[calc(4vw-2px)] lg:h-[calc(3vw-4px)] lg:w-[calc(3vw-4px)] bg-gray-900 hover:bg-myCherryRed"></div>';
    for (var k = 1; k <= 1000; k++) {
      el +=
        '<div class = " transition-colors duration-[1.5s] hover:duration-[0s] border-myCherryRed h-[calc(5vw-2px)] w-[calc(5vw-2px)] md:h-[calc(4vw-2px)] md:w-[calc(4vw-2px)] lg:h-[calc(3vw-4px)] lg:w-[calc(3vw-4px)] bg-gray-900 hover:bg-myCherryRed"></div>';
    }

    para.innerHTML = el;
    document.getElementById("myDIV").appendChild(para);
  }, []);

  const users = [
    {
      login: "aqllimam",
      password: "judayam99",
    },
    {
      login: "darsqilaman",
      password: "req102030",
    },
  ];

  const onLogin = (e) => {
    e.preventDefault();

    let newUser = null;
    users.forEach((user) => {
      if (
        user.login == usernameInp.trim() &&
        user.password == passwordInp.trim()
      ) {
        console.log("1");
        newUser = user;
        authContext.setUser(user);
        return;
      }
    });

    if (newUser != null) {
      authContext.setUser(newUser);
    } else {
      alert("Uzur noto'gri login yoki parol!");
    }
  };

  return (
    <div className=" absolute inset-0 body bg-white dark:bg-[#0F172A]">
      <div className="bg-black before:animate-pulse before:bg-gradient-to-b before:from-gray-900 overflow-hidden before:via-[#00FF00] before:to-gray-900 before:absolute ">
        <div id="myDIV">
          <div className="w-[100vw] h-[100vh] px-3 sm:px-5 flex flex-col items-center justify-center absolute">
            <form
              onSubmit={onLogin}
              className="w-full max-w-xl px-6 bg-gray-500 bg-opacity-20 bg-clip-padding backdrop-filter backdrop-blur-sm text-white z-50 py-4  rounded-lg"
            >
              <div className="w-full flex justify-center text-myCherryRed font-semibold text-2xl mb:2 md:mb-5">
                Portalga kirish
              </div>
              <div className="mb-6">
                <label
                  htmlFor="email"
                  className="block mb-2 text-md font-medium text-white"
                >
                  Login
                </label>
                <input
                  type="text"
                  id="email"
                  className="bg-gray-50 focus:outline-none focus:ring-1 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-myBrightRed focus:border-myBrightRed block w-full p-1.5 md:p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-myBrightRed dark:focus:border-myBrightRed"
                  placeholder=""
                  required
                  onChange={(e) => setUsernameInp(e.target.value)}
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="password"
                  className="block mb-2 text-md font-medium text-white"
                >
                  Parol
                </label>
                <input
                  type="password"
                  id="password"
                  onChange={(e) => setPasswordInp(e.target.value)}
                  className="bg-gray-50 focus:outline-none focus:ring-1 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-myBrightRed focus:border-myBrightRed block w-full p-1.5 md:p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-myBrightRed dark:focus:border-myBrightRed"
                  required
                />
              </div>
              <input
                className="mt-4 mb-8 text-white md:mt-10 w-full flex justify-center text-sm md:text-xl bg-myCherryRed py-2 rounded-md hover:bg-opacity-80 hover:cursor-pointer"
                type="submit"
                value="Kirish"
              />
              {/* <button 
              className="mt-4 mb-8 md:mt-10 w-full flex justify-center text-sm md:text-xl bg-myCherryRed py-2 rounded-md hover:bg-opacity-80 hover:cursor-pointer">
                Kirish
              </button> */}
              <span className="text-sm inline-block  text-white text-center">
                Portalga faqat Sardoretto IT O'quv Markaziga offline yoki online
                qatnashayotgan o'quvchilar kira oladi!
              </span>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthBox;
