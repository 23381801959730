import { useContext, useEffect, useState } from "react";
import { CoursesContext } from "../../utils/coursesContext";
import { db } from "../../utils/firebase";
import CourseListItem from "./courseListItem";
import { useParams } from "react-router-dom";
import LoadingKit from "../../utils/loading";
import { collection, getDocs } from "firebase/firestore";
import AuthBox from "./authBox";
import { AuthContext } from "../../utils/authContext";
import { Link } from "react-router-dom";

function DarsimizPage() {
  const { courseId, lectureId } = useParams();
  const [currentCourse, setCurrentCourse] = useState(null);
  const [currentLecture, setCurrentLecture] = useState(null);

  const coursesContext = useContext(CoursesContext);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      const querySnap = await getDocs(collection(db, "courses"));
      return querySnap;
    };
    if (coursesContext.coursesList.length == 0) {
      let list = [];
      fetchData().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          list.push({
            id: doc.id,
            ...doc.data(),
          });
        });

        list.forEach((course) => {
          if (course.id == courseId) {
            setCurrentCourse(course);
            // console.log("salom23");
            // if (course.type == "paid") {
            //   console.log("salom1111");
            //   return <AuthBox />;
            // }
            course.lectures.forEach((lecture) => {
              if (lecture.id == lectureId) {
                setCurrentLecture(lecture);
              }
            });
          }
        });

        coursesContext.setCoursesList(list);
      });
    } else {
      coursesContext.coursesList.forEach((course) => {
        if (course.id == courseId) {
          setCurrentCourse(course);
          course.lectures.forEach((lecture) => {
            if (lecture.id == lectureId) {
              setCurrentLecture(lecture);
            }
          });
        }
      });
    }
  }, [lectureId]);

  if (currentCourse == null) {
    return <LoadingKit />;
  } else if (currentCourse.type == "paid" && authContext.user == null) {
    return <AuthBox />;
  }

  return (
    <div className=" bg-black min-h-screen flex flex-col-reverse md:flex-row justify-between md:space-x-10 overflow-scroll ">
      <div className="bg-slate-900  basis-1/4  h-screen overflow-scroll">
        <div className="bg-black bg-opacity-40 p-4 text-lg">
          <h2 className=" px-2 text-myCherryRed ">Darsimiz xaritasi</h2>
        </div>
        <div className="p-4   space-y-2 overflow-scroll">
          {currentCourse.lectures.map((lecture, index) => {
            return (
              <CourseListItem
                key={lecture.id}
                isActive={lecture.id == lectureId}
                title={lecture.name}
                position={index + 1}
                to={`/darsimiz/${currentCourse.id}/${lecture.id}`}
              />
            );
          })}
        </div>
      </div>
      <div className="px-10 basis-3/4 h-screen overflow-scroll">
        <Link
          className=" inline-block text-myCherryRed text-xl font-mono mt-4 mb-6 md:float-right hover:text-white"
          to={"/"}
        >
          Sardoretto
        </Link>
        <iframe
          className="w-full aspect-video rounded-lg"
          src={currentLecture.url}
          title="ludovico einaudi - nuvole bianche (slowed)"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
        <div className="my-4 space-y-2">
          <span className=" text-gray-600 text-sm flex items-center">
            {heartIcon} bilan SARDORETTO taqdim etdi
          </span>
          <div className=" text-gray-500 text-md flex flex-col md:flex-row items-start md:items-center space-y-2 md:space-y-0 md:space-x-2">
            <span>
              Dasturlashni o'rganmoqchimisiz? Savolingiz bormi? Telegram orqali
              aloqaga chiqing!
            </span>
            <a
              href="https://t.me/obunetto"
              target="__blank"
              className="  bg-myCherryRed px-2 py-1 rounded text-white font-semibold hover:bg-myBrightRed"
            >
              @obunetto
            </a>
          </div>
          <h2 className=" text-xl md:text-3xl font-semibold text-gray-300">
            {currentLecture.name}
          </h2>
        </div>
      </div>
    </div>
  );
}

export default DarsimizPage;

const heartIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-4 h-4 mr-2"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
    />
  </svg>
);
